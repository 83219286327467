import React, { useState } from 'react';
import Dash from './Dash';
import Sched from './Sched';
import logo from './logo.svg';
import { format } from 'date-fns';

const FarmPanel = ({ farmServers, farmno, farmname, detail, sched, group }) => {
  const [ops, setOps] = useState(null);
  const [stat1, setStat1] = useState(null);
  const [stat2, setStat2] = useState(null);
  const [stat3, setStat3] = useState(null);

  // Whole Farm control
  function farmstop(farm, iplist, farmname, group) {
    setStat3(farmname);
    setStat2(`${format(new Date().toLocaleString(), 'eee HH:mm')} Stopping`);
    // stop the bot on each farm
    const ipstofarm = iplist.filter(server => server.tags.includes(group));
    // alert(`ipstofarm ${ipstofarm}`);
    // loop thru all ips
    ipstofarm.forEach(function (server) {
      //alert(`singleiptofarm ${JSON.stringify(server.ipv4)}`);
      // make the call to the api
      fetch(`https://treea${server.label.slice(-2)}.cherry2024.com:2096/cherryoff?farmno=${farm}`)
        .then(response => response.json())
        .then(ops => setOps(ops))
        .catch(error => console.error(error));
    });
    alert(`All the ROBOTS for the ${farmname} Farm have beed STOPPED. Please wait two (2) minutes for things to change before freaking out. This will only last for today.`);
  }
  function farmstart(farm, iplist, farmname, group) {
    setStat3(farmname);
    setStat2(`${format(new Date().toLocaleString(), 'eee HH:mm')} Starting`);
    // start the bot on each farm
    const ipstofarm = iplist.filter(server => server.tags.includes(group));
    // alert(`ipstofarm ${ipstofarm}`);
    // loop thru all ips
    ipstofarm.forEach(function (server) {
      //alert(`singleiptofarm ${JSON.stringify(server.ipv4)}`);
      // make the call to the api
      fetch(`https://treea${server.label.slice(-2)}.cherry2024.com:2096/cherryon?farmno=${farm}`)
        .then(response => response.json())
        .then(ops => setOps(ops))
        .catch(error => console.error(error));
    });
    alert(`All the ROBOTS for the ${farmname} Farm have beed started. Please wait two (2) minutes for things to change before freaking out.`);
  }

  return (
    <div className="border border-end border-start border-warning">
      {farmServers ?
        <div>
          <div className="row">
            <span className="col-sm-12 d-flex align-items-center justify-content-center detailtxt">
              <h2>{farmname}&nbsp;<img src={logo} className="App-logo" alt="logo" />&nbsp;Farm</h2>
            </span>
          </div>
          <div className="row">
            <span className="col-sm-6 d-flex align-items-center justify-content-center ">
              <button className="btn btn-sm btn-outline-info reginfo up" role="button" onClick={() => farmstart(farmno, farmServers.data, farmname, group)}>Start Farm</button>
            </span>
            <span className="col-sm-6 d-flex align-items-center justify-content-center">
              <button className="btn btn-sm btn-outline-info reginfo down" role="button" onClick={() => farmstop(farmno, farmServers.data, farmname, group)}>Stop Farm</button>
            </span>
          </div>

          <div className="row">
            <span>&nbsp;
              {(sched) &&
                <span className="col-sm-12 align-items-center justify-content-center">
                  <Sched
                  farmno={farmno}
                  />
                </span>
              }
            </span>
          </div>
          
          <div className="row">
              {(detail) &&
                <span className="col-sm-12 align-items-center justify-content-center botshdr">{farmname} Robots</span>
              }
          </div>

          {(detail) && farmServers.data
            .filter(server => server.tags.includes(group))
            .sort((a, b) =>
              (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 :
                ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0))
            .map((item, index) => (
              <div key={item.id} className="row">
                <span className="col-sm-12 d-flex  align-items-center justify-content-center ">
                  &nbsp;{item.label}&nbsp;
                  <Dash
                    farmno={farmno}
                    server={item.label}
                  />
                </span>
              </div>
            ))
          }

          <div className="row-fluid">
            <span>
              {(detail) &&
                <hr></hr>
              }
            </span>
          </div>

          <div className="row-fluid">
              {(stat2) &&
                <span className="d-flex align-items-center justify-content-center shorttext ">
                  {stat2}&nbsp;{stat3}
                </span>
              }
          </div>
        </div>
        : <div>FarmPanel Loading...</div>}
    </div>
  );
};

export default FarmPanel;





